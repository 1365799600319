import React from "react";
import styled, { css } from "styled-components";
import NextLink from 'next/link';

export const HeaderWrapper = styled.header`
  height: var(--headerCompact);
  margin: 0 0 var(--mainPadding);

  @media screen and (min-width: 1128px) {
    margin: 0 0 var(--mainPaddingDesktop);
  }
`;

const HeaderNavWrapper = styled.div`
  transition: all 0.3s ease-in-out;
  border-bottom: 1px solid transparent;
  filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))
    drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 var(--mainPadding);
    margin: 0 auto;
    gap: var(--spacingS);
    height: var(--headerMobile);
    transition: all 0.3s ease-in-out;

    @media screen and (min-width: 1128px) {
      max-width: calc(1128px + (var(--mainPaddingDesktop) * 2));
      padding: 0 var(--mainPaddingDesktop);
    }
  }

  ${({ isSticky }) =>
    isSticky &&
    css`
      --headerMobile: var(--headerCompact);
      --header: var(--headerCompact);
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      z-index: 10;
      background-color: white;
      border-bottom: 1px solid var(--gray100);
    `}
`;

export const HeaderNav = ({ children, ...props }) => {
  return (
    <HeaderNavWrapper {...props}>
      <div>{children}</div>
    </HeaderNavWrapper>
  );
};
const commonStyles = css`
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 168px;
  padding: 0 var(--spacingS);
  text-align: center;
  height: var(--header);
  text-decoration: none;
  background-color: var(--secondary100);
  border-radius: 0 0 var(--radiusXL) var(--radiusXL);
  color: var(--primary800);
  font-size: var(--textXL);
  line-height: var(--lineHeightXL);
  -webkit-font-smoothing: antialiased;

  > img {
    max-width: 120px;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    background-color: var(--secondary200);
  }

  @media screen and (max-width: 1128px) {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    ${({ modal }) =>
      modal &&
      css`
        position: static;
        height: auto;
        padding: var(--spacingM) 0 0 0;
      `}
  }

  ${({ isSticky }) =>
    isSticky &&
    css`
      padding-left: 0;
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }
      > img {
        max-width: 90px;
      }
    `}
`;

export const Logo = styled.div`
  ${commonStyles}
`;

export const LogoLink = styled(NextLink)`
  ${commonStyles}
`;
export const NavigationMenuList = styled.ol`
  display: none;
  flex-direction: row;
  height: var(--headerMobile);
  padding: 0 0 0 var(--spacingS);
  gap: var(--spacingS);
  align-items: center;
  list-style: none;
  margin: 0;
  > li {
    height: var(--spacing2Xl);
  }

  @media screen and (min-width: 1128px) {
    display: flex;
    height: var(--header);
  }
`;

export const BlankSpace = styled.div`
  height: var(--spacingXs);
`;

export const LinksGroupWrapper = styled.ol`
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 0 0 var(--radiusXs) var(--radiusXs);
  overflow: hidden;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);

  > li {
    &:first-child > a {
      border-top: 1px solid var(--gray);
      padding-top: var(--spacingS);
    }
    > a {
      display: block;
      padding: var(--spacingXs) var(--spacingS);
      border-bottom: 1px solid var(--gray200);
      transition: all 0.2s;

      &:hover {
        background-color: var(--gray100);
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
    &:last-child > a {
      border-bottom: none;
      padding-bottom: var(--spacingS);
    }
  }
`;

export const MobileCtaList = styled.ol`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  gap: var(--spacingS);
  width: 100%;
  height: var(--fixed-ctas-height);
  padding: 0 var(--spacingS);
  margin: 0;
  list-style: none;
  background: white;
  border-top: 1px solid var(--gray100);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  box-shadow: 0 -1px 2px rgb(0 0 0 / 0.1), 0 -2px 1px rgb(0 0 0 / 0.06);

  > li {
    width: 100%;
    button,
    a {
      width: 100%;
    }
  }

  > li:first-child button,
  > li:first-child a {
    background-color: var(--cta800);
    color: white;
    &:hover,
    &:focus {
      background-color: var(--cta400);
    }
  }

  > li:last-child button,
  > li:last-child a {
    background-color: var(--primary800);
    color: white;
    &:hover,
    &:focus {
      background-color: var(--primary400);
    }
  }

  @media screen and (min-width: 1128px) {
    display: none;
  }

  @media screen and (max-width: 400px) {
    > li {
      button {
        font-size: var(--textS);
      }
    }
  }

  ${({ isVisible }) => isVisible && "visibility:visible; opacity: 100%"}
`;

export const MobileNavigationMenuList = styled.ol`
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  margin: 0;
`;

export const LinkNavigationGroupMobileWrapper = styled.li`
  width: 100%;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacingS) var(--spacingM);
    font-size: 24px;
    line-height: 36px;
    border-bottom: 1px solid var(--gray200);
    ${({ marked }) =>
      marked &&
      css`
        background-color: #eff5f5;
      `}
  }
  > ol {
    background-color: #ffffff;
    > li {
      border-bottom: 1px solid var(--gray200);

      &:last-child {
        border-bottom: none;
      }

      > a,
      span {
        display: block;
        font-size: 18px;
        line-height: 36px;
        padding: var(--spacingXxs) var(--spacingM);
      }

      > span {
        background-color: var(--gray100);
      }
    }
  }
`;

export const ModalMobileNavigationHeaderList = styled.ol`
  display: flex;
  flex-direction: row;
  gap: var(--spacingS);
  height: var(--headerMobile);
  align-items: center;
  list-style: none;
  margin: 0;
  > li {
    height: var(--spacing2Xl);
  }

  @media screen and (min-width: 1128px) {
    height: var(--header);
  }
`;

export const NavigationMenu = styled.nav``;

export const MobileMenuButton = styled.a`
  display: flex;
  align-items: center;
  gap: var(--spacingXs);
  @media screen and (min-width: 1128px) {
    display: none;
  }
`;

export const CallAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border: 1px solid var(--primary800);
  border-radius: 99px;
  cursor: pointer;

  a {
    color: transparent;
    max-height: 38px;
    max-width: 38px;
    overflow: hidden;
    svg {
      position: relative;
      top: 9px;
      left: 9px;
    }
  }

  @media screen and (min-width: 1128px) {
    display: none;
  }
`;

export const ModalNavigationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--gray100);
  overflow: auto;

  ${({ withCta }) =>
    withCta &&
    css`
      padding-bottom: var(--fixed-ctas-height);
    `}
`;
