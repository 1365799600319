import dynamic from "next/dynamic";
import styled from "styled-components";
import { CaretDownIcon } from "../../lib/icons";

const Link = dynamic(() => import("../elements/link/link"));

const LocaleDropdown = styled.div`
  display: none;
  position: relative;
  flex-direction: column;
  color: var(--gray);
  text-align: left;
  min-width: 80px;

  @media screen and (min-width: 1128px) {
    display: flex;
  }

  > span > svg {
    display: inline;
    margin-left: 5px;
  }

  > span,
  > a {
    display: block;
    height: var(--spacing2Xl);
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    text-transform: capitalize;
    background-color: var(--gray100);
    border-radius: var(--radiusXs);
    padding: 0 var(--spacingS);
    font-size: var(--textM);
    line-height: calc(var(--spacing2Xl) - 4px);
    transition: all 0.2s;
    &:hover {
      background-color: var(--gray200);
    }
  }

  > a {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
  }

  &:hover {
    > span {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    > a {
      display: block;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
`;

const MobileLink = styled.div`
  display: block;
  width: 100%;
  border-radius: var(--radiusL);
  background-color: var(--gray100);
  overflow: hidden;

  @media screen and (min-width: 1128px) {
    display: none;
  }
`;

export const getLocaleLink = ({ localizations, locale }) => {
  const alternativeLocalePage = localizations[0];
  if (alternativeLocalePage) {
    return { page: alternativeLocalePage };
  }
  return {
    page: { slug: "", locale },
  };
};

export const LocaleSelector = ({ localizations = [], activedLocale }) => {
  const alternativeLocale = activedLocale === "es" ? "ca" : "es";
  const alternativeLocaleLink = getLocaleLink({
    localizations,
    locale: alternativeLocale,
  });

  return (
    <div>
      <LocaleDropdown>
        <span>
          {activedLocale} {CaretDownIcon}
        </span>
        <Link target="_self" {...alternativeLocaleLink}>
          {alternativeLocale}
        </Link>
      </LocaleDropdown>
    </div>
  );
};

export default LocaleSelector;
