import { useEffect, useState } from 'react';
import { PhoneFilled, ChevronDown, ChevronUp } from '@carbon/icons-react';
import dynamic from 'next/dynamic';
import Modal, { useModal } from '../modal';
import { NextImage } from '../elements';
import LocaleSelector, { getLocaleLink } from './locale-selector';
import {
  LogoLink,
  Logo,
  HeaderWrapper,
  NavigationMenu,
  NavigationMenuList,
  BlankSpace,
  LinksGroupWrapper,
  MobileMenuButton,
  MobileNavigationMenuList,
  LinkNavigationGroupMobileWrapper,
  MobileCtaList,
  HeaderNav,
  CallAction,
  ModalNavigationWrapper,
} from './styles';
import { HamburgerMenuIcon } from '../../lib/icons';
import { useStickyElement, useIntersectionObserver } from '../../lib/hooks';
import { getPhoneNumber } from '../../utils';

const Link = dynamic(() => import('../elements/link/link'));

const LinkNavigationGroupMobile = ({
  linkGroup,
  showGroup,
  onClick,
  marked,
}) => {
  const { parentLink, links } = linkGroup;
  const hasChildLinks = links.length > 0;
  const Icon = showGroup ? ChevronUp : ChevronDown;

  if (!parentLink) {
    return null;
  }

  return (
    <LinkNavigationGroupMobileWrapper marked={marked}>
      <div onClick={onClick}>
        <Link {...parentLink} noUnderline />
        {hasChildLinks && <Icon size={32} />}
      </div>
      {hasChildLinks && showGroup && (
        <ol>
          {links.map((childLink) => (
            <li key={`link-navigation-group-mobile-wrapper-${childLink.id}`}>
              <Link {...childLink} noUnderline />
            </li>
          ))}
        </ol>
      )}
    </LinkNavigationGroupMobileWrapper>
  );
};

const LinkNavigationLocalizationMobile = ({
  showGroup,
  onClick,
  localizations,
  activedLocale,
}) => {
  const Icon = showGroup ? ChevronUp : ChevronDown;
  const alternativeLocale = activedLocale === 'es' ? 'ca' : 'es';
  const alternativeLocaleLink = getLocaleLink({
    localizations,
    locale: alternativeLocale,
  });

  return (
    <LinkNavigationGroupMobileWrapper>
      <div onClick={onClick}>
        <span>Idioma</span>
        <Icon size={32} />
      </div>
      {showGroup && (
        <ol>
          <li>
            <span>{activedLocale === 'es' ? 'Castellano' : 'Català'}</span>
          </li>
          <li>
            <Link target="_self" {...alternativeLocaleLink} noUnderline>
              {alternativeLocale === 'es' ? 'Castellano' : 'Català'}
            </Link>
          </li>
        </ol>
      )}
    </LinkNavigationGroupMobileWrapper>
  );
};

const LinkNavigationGroupsMobile = ({
  linkGroups,
  localizations,
  activedLocale,
}) => {
  const [wichOpen, setWichOpen] = useState(null);

  return (
    <>
      {linkGroups.map((linkGroup) => (
        <LinkNavigationGroupMobile
          key={`link-navigation-group-mobile-${linkGroup.parentLink.id}`}
          linkGroup={linkGroup}
          showGroup={wichOpen === linkGroup.parentLink.id}
          onClick={() =>
            setWichOpen((prev) =>
              prev === linkGroup.parentLink.id ? null : linkGroup.parentLink.id
            )
          }
          marked={
            linkGroup.parentLink.text === 'Empleo' ||
            linkGroup.parentLink.text === 'Treball'
          }
        />
      ))}
      <LinkNavigationLocalizationMobile
        showGroup={wichOpen === -1}
        onClick={() => setWichOpen((prev) => (prev === -1 ? null : -1))}
        localizations={localizations}
        activedLocale={activedLocale}
      />
    </>
  );
};

const LinkNavigationGroupDesktop = ({ linkGroup }) => {
  const [showGroup, setShowGroup] = useState(false);
  const { parentLink, links } = linkGroup;
  const hasChildLinks = links.length > 0;

  if (!parentLink) {
    return null;
  }

  return (
    <li
      onMouseEnter={() => setShowGroup(true)}
      onMouseLeave={() => setShowGroup(false)}
    >
      <Link type="buttonHeader" {...parentLink} />
      {hasChildLinks && showGroup && (
        <>
          <BlankSpace />
          <LinksGroupWrapper>
            {links.map((childLink) => (
              <li key={`link-group-wrapper-${childLink.id}`}>
                <Link {...childLink} noUnderline />
              </li>
            ))}
          </LinksGroupWrapper>
        </>
      )}
    </li>
  );
};

const Ctas = ({ links = [], closeModal = null }) => {
  if (!links.length) {
    return null;
  }
  return links.map((link) => {
    return (
      <li
        key={`ctas-${link.id}`}
        onClick={() => (closeModal ? closeModal() : null)}
      >
        <Link type="buttonPrimary" {...link} />
      </li>
    );
  });
};

const mergeNavigation = (headerNavigation = {}, pageNavigation = {}) => {
  return {
    phoneLink: pageNavigation.phoneLink || headerNavigation.phoneLink,
    linksDesktop: pageNavigation.linksDesktop?.length
      ? pageNavigation.linksDesktop
      : headerNavigation.linksDesktop || [],
    linksMobile: pageNavigation.linksMobile?.length
      ? pageNavigation.linksMobile
      : headerNavigation.linksMobile || [],
    ctasDesktop: pageNavigation.ctasDesktop?.length
      ? pageNavigation.ctasDesktop
      : headerNavigation.ctasDesktop || [],
    ctasMobile: pageNavigation.ctasMobile?.length
      ? pageNavigation.ctasMobile
      : headerNavigation.ctasMobile || [],
    hideCtasMobile:
      pageNavigation.hideCtasMobile || headerNavigation.hideCtasMobile,
  };
};

const useMobileCtaListVisible = (navigation) => {
  const [isMobileCtaListVisible, setIsMobileCtaListVisible] = useState(false);

  const formEntry = useIntersectionObserver('#form-section', {
    threshold: 0.3,
  });
  const sliderEntry = useIntersectionObserver('#slider-above-section', {
    threshold: 0.3,
  });

  const formIsVisible = formEntry?.isIntersecting;
  const sliderIsVisible = sliderEntry?.isIntersecting;

  useEffect(() => {
    if (
      !formIsVisible &&
      !sliderIsVisible &&
      !navigation.hideCtasMobile &&
      !!navigation.ctasMobile.length
    ) {
      setIsMobileCtaListVisible(true);
    } else {
      setIsMobileCtaListVisible(false);
    }
  }, [formIsVisible, navigation]);

  return isMobileCtaListVisible;
};

export const Header = ({ header, globalMicrodata, pageContext, locale }) => {
  const headerNavigation = header?.navigation || {};
  const pageNavigation = pageContext.navigation?.navigation || {};
  const navigation = mergeNavigation(headerNavigation, pageNavigation);
  const { triggerProps, modalProps, state } = useModal();
  const { elementRef, isSticky, isHidden } = useStickyElement();
  const isMobileCtaListVisible = useMobileCtaListVisible(navigation);
  const localePrefix = locale === 'ca' ? '/ca' : '';

  return (
    <HeaderWrapper ref={elementRef}>
      <HeaderNav isSticky={true} isHidden={isHidden}>
          <LogoLink
              href={{
                pathname: `${localePrefix}/[[...slug]]`,
                query: { slug: '' },
              }}
            passHref
            target="_self" isSticky={true}>
            {globalMicrodata?.logo ? (
              <NextImage media={globalMicrodata.logo} alt="Qida" />
            ) : (
              'Qida'
            )}
          </LogoLink>
        <NavigationMenu>
          <NavigationMenuList>
            {navigation.linksDesktop.map((linkGroup) => (
              <LinkNavigationGroupDesktop
                key={`link-navigation-group-desktop-${linkGroup.parentLink.id}`}
                linkGroup={linkGroup}
                isDesktop
              />
            ))}
            <Ctas links={navigation.ctasDesktop} />
            <li key="localeSelector">
              <LocaleSelector
                localizations={pageContext?.localizations}
                activedLocale={locale}
              />
            </li>
          </NavigationMenuList>
          {!state.isOpen && (
            <MobileMenuButton {...triggerProps}>
              {HamburgerMenuIcon}
            </MobileMenuButton>
          )}
        </NavigationMenu>
   
        <CallAction>
          <Link {...navigation.phoneLink}>
            <PhoneFilled size="20" color="var(--primary800)" />
            {getPhoneNumber(navigation.phoneLink, true)}
          </Link>
        </CallAction>
        <Modal {...modalProps} fullvh closeLeft>
          <ModalNavigationWrapper withCta={navigation.ctasMobile.length > 0}>
            
              <LogoLink
                   href={{
                    pathname: `${localePrefix}/[[...slug]]`,
                    query: { slug: '' },
                  }}
                  passHref
                modal target="_self" isSticky={true}>
                {globalMicrodata?.logo ? (
                  <NextImage media={globalMicrodata.logo} alt="Qida" />
                ) : (
                  'Qida'
                )}
              </LogoLink>
           
            <MobileNavigationMenuList>
              <LinkNavigationGroupsMobile
                linkGroups={navigation.linksMobile}
                localizations={pageContext?.localizations}
                activedLocale={locale}
              />
            </MobileNavigationMenuList>
            {navigation.ctasMobile.length > 0 && (
              <MobileCtaList isVisible={true}>
                <Ctas
                  links={navigation.ctasMobile}
                  closeModal={modalProps.state.close}
                />
              </MobileCtaList>
            )}
          </ModalNavigationWrapper>
        </Modal>
      </HeaderNav>
      <MobileCtaList isVisible={isMobileCtaListVisible}>
        <Ctas links={navigation.ctasMobile} />
      </MobileCtaList>
    </HeaderWrapper>
  );
};

export const EmptyHeader = ({
  header,
  globalMicrodata,
  pageContext,
  locale,
}) => {
  const headerNavigation = header?.navigation || {};
  const pageNavigation = pageContext.navigation?.navigation || {};
  const navigation = mergeNavigation(headerNavigation, pageNavigation);
  const { modalProps } = useModal();
  const { elementRef, isSticky, isHidden } = useStickyElement();
  const localePrefix = locale === 'ca' ? '/ca' : '';

  return (
    <HeaderWrapper ref={elementRef}>
      <HeaderNav isSticky={true} isHidden={isHidden}>
        <Logo isSticky={true}>
          {globalMicrodata?.logo ? (
            <NextImage media={globalMicrodata.logo} alt="Qida" />
          ) : (
            'Qida'
          )}
        </Logo>
        <Modal {...modalProps} fullvh closeLeft>
          <ModalNavigationWrapper withCta={navigation.ctasMobile.length > 0}>
          
              <LogoLink
                   href={{
                    pathname: `${localePrefix}/[[...slug]]`,
                    query: { slug: '' },
                  }}
                passHref
                modal target="_self" isSticky={true}>
                {globalMicrodata?.logo ? (
                  <NextImage media={globalMicrodata.logo} alt="Qida" />
                ) : (
                  'Qida'
                )}
              </LogoLink>
           
            <MobileNavigationMenuList>
              <LinkNavigationGroupsMobile
                linkGroups={[]}
                localizations={pageContext?.localizations}
                activedLocale={locale}
              />
            </MobileNavigationMenuList>
            {navigation.ctasMobile.length > 0 && (
              <MobileCtaList isVisible={true}>
                <Ctas
                  links={navigation.ctasMobile}
                  closeModal={modalProps.state.close}
                />
              </MobileCtaList>
            )}
          </ModalNavigationWrapper>
        </Modal>
      </HeaderNav>
    </HeaderWrapper>
  );
};

export default Header;
